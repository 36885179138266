<template>
  <div>
    <v-row>
    <v-col cols="2" sm="3">
      <v-sheet class="text mt-5 ml-1">{{ label }}</v-sheet>
    </v-col>
    <v-col cols="3" :data-cy="name + '_real_col'">
      <!-- real part -->
      <v-text-field
        :data-cy="name + '_real'"
        label="real"
        type="number"
        :rules="required === true ? req : []"
        v-model.number="localValue[0]"
        @input="localValue = [parseFloat($event), value[1]]"
        :messages="[hints[0]]"
      ></v-text-field
    ></v-col>
    <v-col cols="3" :data-cy="name + '_imaginary_col'">
      <!-- imaginary part -->
      <v-text-field
        :data-cy="name + '_imaginary'"
        label="imaginary"
        type="number"
        :rules="required === true ? req : []"
        v-model.number="localValue[1]"
        @input="localValue = [value[0], parseFloat($event)]"
        :messages="[hints[1]]"
      ></v-text-field
    ></v-col>
    <v-col md="1" sm="2">
      <v-sheet :data-cy="name + '_unit'" class="text mt-5 ml-n3">{{ unit }}</v-sheet>
    </v-col>
    <v-col cols="1">
      <DynamicInfoDialog :info="doc" :headerTitle="label"></DynamicInfoDialog>
    </v-col>
  </v-row>
  <v-row>
      <span
        :data-cy="name + '_hint'"
        class="text text-caption font-weight-light mt-n6 ml-4"
        >{{ hint }}</span
      >
    </v-row>
  </div>
</template>

<script>
const DynamicInfoDialog = () => import('./DynamicInfoDialog.vue')
import { cloneDeep } from 'lodash'
import { mapMutations } from 'vuex'

export default {
  components: { DynamicInfoDialog },

  data: () => ({
    req: [(v) => !!v || v === 0 || 'required'],
  }),

  props: {
    value: [Array, String],
    required: Boolean,
    label: String,
    name: String,
    doc: String,
    unit: String,
    hint: String,
    hints: Array,
    valuePath: Array,
    isNestedFormMultiple: Boolean,
  },

  methods: {
    ...mapMutations('dynamicForms', [
      'setValue',
      'setMultipleValue',
      'setDictValue',
    ]),
  },

  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        let path = cloneDeep(this.valuePath)
        if (this.isNestedFormMultiple) {
          this.$emit('update:value', this.setMultipleValue([path, value]))
        } else if (path.length > 1) {
          this.$emit('update:value', this.setDictValue([path, value]))
        } else {
          this.$emit('update:value', this.setValue([path, value]))
        }
      },
    },
  },
}
</script>

<style scoped>
div.col.col-3 {
  padding: 4px !important;
}

div.col.col-1 {
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-top: 4px !important;
}
</style>
